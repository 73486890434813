h2 {
  margin-top: 0;
}

.header {
  padding-left: 1rem;
  height: 44px;
  background-color: #F7F7F7;
  border-bottom: 1px solid #c2c2c2;
}

.header > h2 {
  padding-top: 10px;
  padding-left: 0;
}


.flex-container {
  display: flex;
}

.settings-content {
  flex-grow: 1;
  /* border-left: 1px solid black; */
}

/* .settings-content * {
  padding-left: 2rem;
} */

.user-header {
  padding-top: 1rem;
  display: flex;
  gap: 10rem;
}

.user-content {
  padding-left: 1rem;
}


.selected-event-container  {
  margin-top: 1rem;
}

.selected-event-header {
  padding-top: 1rem;
  margin-bottom: 0;
  padding-left: 1rem;

}
.selected-event-desctiption {
  margin-top: 1rem;
  margin-top: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;

}

.selected-event-container h4  {
  margin-bottom: 0rem;
  padding-left: 1rem;
}

.selected-event-crew, .selected-event-equipment {
  padding-left: 1rem;

}

/* .selected-event-crew:last-of-type {
  border-bottom: 1px solid black;

} */
/* .selected-event-equipment:last-of-type {
  border-top: 1px solid black;
  
} */


.agenda-calendar {
  flex-grow: 1;
}

@media only screen and (max-width: 700px) {
  .flex-container {
    flex-direction: column-reverse;
  }

}


@media only screen and (min-width: 700px) {

  
  .flex-container {
    flex-direction: column-reverse;
  }
}




/* calendar picker */

.md-switching-view-cont {
  height: 90%;
}

.md-switching-view-cont .mbsc-segmented {
  margin: 0;
}

.md-switching-view-cont .mbsc-segmented.mbsc-material,
.md-switching-view-cont .mbsc-segmented.mbsc-windows {
  padding: 0;
}

.md-switching-view-cont .mbsc-segmented .mbsc-segmented-button {
  padding: 0 1px;
}

.md-switching-view-cont .mbsc-segmented .mbsc-icon {
  font-size: 20px;
}

.md-switching-view-cont .mbsc-segmented-button.mbsc-windows {
  border: 0;
}

.cal-header-picker {
  -webkit-flex: 1 0 auto;
  -moz-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cal-header-nav {
  width: 165px;
}

.cal-header-nav .mbsc-calendar-title.mbsc-material {
  font-size: 16px;
}


.footer {
  background-color: #2e2e2e;
  padding: 2rem;
}

.reset-pwd-btn {
  padding: 0.3rem 1rem 0.3rem 1rem;
  list-style: none;
  text-decoration: none;
  color: white;
}
.reset-pwd-btn:hover {
  padding: 0.3rem 1rem 0.3rem 1rem;
  cursor: pointer;
  color: #3598b7;
}


a {
  padding: 0.3rem 1rem 0.3rem 1rem;
  list-style: none;
  text-decoration: none;
  color: white;
}

a:hover {
  padding: 0.3rem 1rem 0.3rem 1rem;
  cursor: pointer;
  color: #3598b7;
}

.confirm-dialog {
  display: flex;
  background-color: #2e2e2e;
  padding: 1rem;
  justify-content: center;

}
.confirm-dialog h2 {
border: 1px solid white;
color: rgb(190, 189, 189);
margin: 0;
padding: 0.5rem 1rem 0.5rem 1rem;
}

.confirm-dialog h2:hover {
  border: 1px solid white;
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 1rem;
  cursor: pointer;
  color: #3598b7;
  }