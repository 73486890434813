/* a {
  all: unset;
} */


ul {
  display: block;
  list-style-type: disc;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.nav-ul {
  /* margin: 1rem; */
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  gap: 2rem;
  padding: 1rem 2rem 1rem 0rem;
  background-color: #2e2e2e;
}


.mobile-menu {
  width: 100%;
  background-color: #2e2e2e;
}
.hamburger-react {
  width: 100%;
  z-index: 999;
}

.logo-image {
  padding-left: 30px;
  height: 50px;
}

a {
  padding: 0.3rem 1rem 0.3rem 1rem;
  list-style: none;
  text-decoration: none;
  color: white;
}

a:hover {
  padding: 0.3rem 1rem 0.3rem 1rem;
  cursor: pointer;
  color: #3598b7;
}

.active {
  color: #3598b7;

}

.regular:hover {
  padding: 0.3rem 1rem 0.3rem 1rem;
  cursor: pointer;
  color: #3598b7;
} 

.log-out-btn {
  padding: 0.3rem 1rem 0.3rem 1rem;
  list-style: none;
  text-decoration: none;
  color: white;
  margin-left: auto
}
.log-out-btn:hover {
  padding: 0.3rem 1rem 0.3rem 1rem;
  cursor: pointer;
  color: #3598b7;
}

.mobile-menu {
  display: none;
}


.mobile-nav-ul-open {
  transition: all 1s;
}
.mobile-nav-ul-closed {
  transition: all 1s;

  /* transform: translatey(-100%); */

}

.buttonContainer {
  width: 100%;
  background-color: #2e2e2e;
  z-index: 9999;
  display: flex;
}

.buttonContainer ul {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 480px) {


  .logo-image {
    display: none;
  }

  .nav-ul {
    display: none;
  }

  .mobile-menu {
    /* margin: 1rem; */
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    padding: 0.5rem 0rem 0.5rem 0rem;
    background-color: #2e2e2e;
    transition: all 2s;
    height: auto;
  }
  /* .nav-ul {
    gap: 4vw;
    padding: 0.5rem;
  }
  .log-out-btn {
    border: 1px solid rgb(148, 148, 148);
    padding: 5px 0 5px 0;

  }

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
} */




/* .nav-ul {
  display: none;
}
.mobile-menu {
  display: block;

} */
}