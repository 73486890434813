$mbsc-ios-accent: #7AD5DD;



/* MOBILE MENU */

.motion-air-calendar .mbsc-timeline-resource-empty.mobile-menu-open,
.motion-air-calendar .mbsc-timeline-resource-col.mobile-menu-open,
.motion-air-calendar .mbsc-timeline-resource.mobile-menu-open,
.mbsc-timeline-resource.mbsc-ios.mbsc-ltr.mobile-menu-open-padding {
  width: 0;
  padding-left: 0 !important;
}

.motion-air-calendar.mbsc-ltr .mobile-toggle .mbsc-schedule-event-inner {
  left: 0 !important;
} 


.md-tooltip-content-container {
  padding: 5px 5px 5px 20px;
}

.md-tooltip-container h1 {
  font-size: 1.2rem;
}

.md-tooltip-container h2 {
  font-size: 1rem;
}

.md-tooltip-container p {
  font-size: 0.8rem;
}

.md-tooltip-header {
  padding: 5px 5px 5px 20px;
}
.md-tooltip-content {

}

.selected-event-container {
  margin-top: 1rem;
  margin-bottom: 0rem;
}

  @import "~@mobiscroll/react/dist/css/mobiscroll.react.scss"