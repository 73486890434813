.main-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100vh;
  background-color: #4b99a1;
}

.welcome-image-wrapper {
  height: 50vw;
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  align-items: center;
  background-color: #3a3a3a;
  border-radius: 3rem;
  max-width: 40rem;
  max-height: 40rem;
}

.welcome-image-wrapper span {
  font-size: 5vw;
  margin-top: 2rem;
  color: #919392;
  padding-right: 1rem;
  padding-left: 1rem;
}

.welcome-image {
  height: 15vw;
  max-width: 30rem;
  max-height: 177px;
}

.buttons {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2rem;
}

/* .forgot {
  width: fit-content;
  padding: 1rem;
  border: 1px solid white;
}

.forgot:hover {
  width: fit-content;
  padding: 1rem;
  border: 1px solid white;
  color: blue;
} */

.forgot-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
}

.forgot-container label {
  color: rgb(182, 182, 182);
}

#forgot {
  width: 80%;
}


.forgot {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 16px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #919392;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  /* background-image: url(../img/icon_email.png); */
  background-color: #3a3a3a;
  background-repeat: no-repeat;
  background-position: 4px 3px;
  background-size: 34px;
}

.forgot:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.forgot:active {
  background-color: #eeeeee;
}

.forgot:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.forgot:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}





.forgot:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}



body {
  
  background-color: #f7f7f7;
  
}



.login-with-email-btn {
  transition: background-color 0.3s, box-shadow 0.3s;

  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #919392;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(../img/icon_email.png);
  background-color: #3a3a3a;
  background-repeat: no-repeat;
  background-position: 4px 3px;
  background-size: 34px;
}

.login-with-email-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
}

.login-with-email-btn:active {
  background-color: #eeeeee;
}

.login-with-email-btn:focus {
  outline: none;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
    0 0 0 3px #c8dafc;
}

.login-with-email-btn:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}




/* body {
  text-align: center;
  padding-top: 2rem;
} */